import { useRef, useEffect } from 'react';
import { MeshStandardMaterial } from 'three';

import { create } from 'zustand';

export const useInterpreterModel = create(set => ({
  model: null,
  overrideMaterial: true,
  setModel: (model, overrideMaterial=true) => set(state => ({ model, overrideMaterial }))
}));

export default function Model(props) {
  const modelRef = useRef();
  const {
    showModel,
    modelScale = 1,
    modelColor,
    modelOpacity
  } = props;

  const {
    model,
    overrideMaterial
  } = useInterpreterModel();

  useEffect(() => {
    if(model && overrideMaterial) {
      const material = new MeshStandardMaterial({ color: modelColor, roughness: .4, metalness: .2, opacity: modelOpacity, transparent: modelOpacity < 1 })
      const objsWithMaterial = [];

      model.traverse((currentObj) => {
        if(currentObj.material) {
          objsWithMaterial.push(currentObj);
        }
      });
      for(const currentObj of objsWithMaterial) {
        if(currentObj.material) {
          currentObj.material = material;
        }
      }
    }
  }, [ model, modelOpacity, modelColor, overrideMaterial ]);

  if(model) {
    return <primitive ref={modelRef} object={model} visible={showModel} scale={[ modelScale, modelScale, modelScale ]}/>
  } else {
    return null;
  }
};
